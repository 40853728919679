// Processed by JSheets Protobuf Packager
// source: jsheets/api/snippet_runtime.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var jsheets_api_snippet_pb = require('../../jsheets/api/snippet_pb.js');
goog.object.extend(proto, jsheets_api_snippet_pb);
goog.exportSymbol('proto.jsheets.CodeSpan', null, global);
goog.exportSymbol('proto.jsheets.EvaluateRequest', null, global);
goog.exportSymbol('proto.jsheets.EvaluateRequest.MessageCase', null, global);
goog.exportSymbol('proto.jsheets.EvaluateResponse', null, global);
goog.exportSymbol('proto.jsheets.EvaluatedSnippet', null, global);
goog.exportSymbol('proto.jsheets.Evaluation', null, global);
goog.exportSymbol('proto.jsheets.EvaluationError', null, global);
goog.exportSymbol('proto.jsheets.EvaluationResult', null, global);
goog.exportSymbol('proto.jsheets.EvaluationResult.Kind', null, global);
goog.exportSymbol('proto.jsheets.MissingSources', null, global);
goog.exportSymbol('proto.jsheets.SnippetSources', null, global);
goog.exportSymbol('proto.jsheets.SnippetSources.CodeComponent', null, global);
goog.exportSymbol('proto.jsheets.SourceCachingPolicy', null, global);
goog.exportSymbol('proto.jsheets.StartEvaluationRequest', null, global);
goog.exportSymbol('proto.jsheets.StopEvaluationRequest', null, global);
goog.exportSymbol('proto.jsheets.Text', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.EvaluatedSnippet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jsheets.EvaluatedSnippet.repeatedFields_, null);
};
goog.inherits(proto.jsheets.EvaluatedSnippet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.EvaluatedSnippet.displayName = 'proto.jsheets.EvaluatedSnippet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.SnippetSources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jsheets.SnippetSources.repeatedFields_, null);
};
goog.inherits(proto.jsheets.SnippetSources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.SnippetSources.displayName = 'proto.jsheets.SnippetSources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.SnippetSources.CodeComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.SnippetSources.CodeComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.SnippetSources.CodeComponent.displayName = 'proto.jsheets.SnippetSources.CodeComponent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.Evaluation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.Evaluation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.Evaluation.displayName = 'proto.jsheets.Evaluation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.StartEvaluationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jsheets.StartEvaluationRequest.repeatedFields_, null);
};
goog.inherits(proto.jsheets.StartEvaluationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.StartEvaluationRequest.displayName = 'proto.jsheets.StartEvaluationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.MissingSources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jsheets.MissingSources.repeatedFields_, null);
};
goog.inherits(proto.jsheets.MissingSources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.MissingSources.displayName = 'proto.jsheets.MissingSources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.StopEvaluationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.StopEvaluationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.StopEvaluationRequest.displayName = 'proto.jsheets.StopEvaluationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.EvaluateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.jsheets.EvaluateRequest.oneofGroups_);
};
goog.inherits(proto.jsheets.EvaluateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.EvaluateRequest.displayName = 'proto.jsheets.EvaluateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.Text = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.Text, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.Text.displayName = 'proto.jsheets.Text';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.EvaluationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.EvaluationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.EvaluationResult.displayName = 'proto.jsheets.EvaluationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.CodeSpan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.CodeSpan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.CodeSpan.displayName = 'proto.jsheets.CodeSpan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.EvaluationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jsheets.EvaluationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.EvaluationError.displayName = 'proto.jsheets.EvaluationError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jsheets.EvaluateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jsheets.EvaluateResponse.repeatedFields_, null);
};
goog.inherits(proto.jsheets.EvaluateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jsheets.EvaluateResponse.displayName = 'proto.jsheets.EvaluateResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jsheets.EvaluatedSnippet.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.EvaluatedSnippet.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.EvaluatedSnippet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.EvaluatedSnippet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluatedSnippet.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && jsheets_api_snippet_pb.Snippet.Reference.toObject(includeInstance, f),
    hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dependenciesList: jspb.Message.toObjectList(msg.getDependenciesList(),
    proto.jsheets.EvaluatedSnippet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.EvaluatedSnippet}
 */
proto.jsheets.EvaluatedSnippet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.EvaluatedSnippet;
  return proto.jsheets.EvaluatedSnippet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.EvaluatedSnippet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.EvaluatedSnippet}
 */
proto.jsheets.EvaluatedSnippet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new jsheets_api_snippet_pb.Snippet.Reference;
      reader.readMessage(value,jsheets_api_snippet_pb.Snippet.Reference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 3:
      var value = new proto.jsheets.EvaluatedSnippet;
      reader.readMessage(value,proto.jsheets.EvaluatedSnippet.deserializeBinaryFromReader);
      msg.addDependencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.EvaluatedSnippet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.EvaluatedSnippet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.EvaluatedSnippet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluatedSnippet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      jsheets_api_snippet_pb.Snippet.Reference.serializeBinaryToWriter
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDependenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jsheets.EvaluatedSnippet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Snippet.Reference reference = 1;
 * @return {?proto.jsheets.Snippet.Reference}
 */
proto.jsheets.EvaluatedSnippet.prototype.getReference = function() {
  return /** @type{?proto.jsheets.Snippet.Reference} */ (
    jspb.Message.getWrapperField(this, jsheets_api_snippet_pb.Snippet.Reference, 1));
};


/**
 * @param {?proto.jsheets.Snippet.Reference|undefined} value
 * @return {!proto.jsheets.EvaluatedSnippet} returns this
*/
proto.jsheets.EvaluatedSnippet.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.EvaluatedSnippet} returns this
 */
proto.jsheets.EvaluatedSnippet.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.EvaluatedSnippet.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.jsheets.EvaluatedSnippet.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluatedSnippet} returns this
 */
proto.jsheets.EvaluatedSnippet.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EvaluatedSnippet dependencies = 3;
 * @return {!Array<!proto.jsheets.EvaluatedSnippet>}
 */
proto.jsheets.EvaluatedSnippet.prototype.getDependenciesList = function() {
  return /** @type{!Array<!proto.jsheets.EvaluatedSnippet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.EvaluatedSnippet, 3));
};


/**
 * @param {!Array<!proto.jsheets.EvaluatedSnippet>} value
 * @return {!proto.jsheets.EvaluatedSnippet} returns this
*/
proto.jsheets.EvaluatedSnippet.prototype.setDependenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jsheets.EvaluatedSnippet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.EvaluatedSnippet}
 */
proto.jsheets.EvaluatedSnippet.prototype.addDependencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jsheets.EvaluatedSnippet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.EvaluatedSnippet} returns this
 */
proto.jsheets.EvaluatedSnippet.prototype.clearDependenciesList = function() {
  return this.setDependenciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jsheets.SnippetSources.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.SnippetSources.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.SnippetSources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.SnippetSources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.SnippetSources.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && jsheets_api_snippet_pb.Snippet.Reference.toObject(includeInstance, f),
    hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    codeComponentsList: jspb.Message.toObjectList(msg.getCodeComponentsList(),
    proto.jsheets.SnippetSources.CodeComponent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.SnippetSources}
 */
proto.jsheets.SnippetSources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.SnippetSources;
  return proto.jsheets.SnippetSources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.SnippetSources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.SnippetSources}
 */
proto.jsheets.SnippetSources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new jsheets_api_snippet_pb.Snippet.Reference;
      reader.readMessage(value,jsheets_api_snippet_pb.Snippet.Reference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 3:
      var value = new proto.jsheets.SnippetSources.CodeComponent;
      reader.readMessage(value,proto.jsheets.SnippetSources.CodeComponent.deserializeBinaryFromReader);
      msg.addCodeComponents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.SnippetSources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.SnippetSources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.SnippetSources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.SnippetSources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      jsheets_api_snippet_pb.Snippet.Reference.serializeBinaryToWriter
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCodeComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jsheets.SnippetSources.CodeComponent.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.SnippetSources.CodeComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.SnippetSources.CodeComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.SnippetSources.CodeComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.SnippetSources.CodeComponent}
 */
proto.jsheets.SnippetSources.CodeComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.SnippetSources.CodeComponent;
  return proto.jsheets.SnippetSources.CodeComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.SnippetSources.CodeComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.SnippetSources.CodeComponent}
 */
proto.jsheets.SnippetSources.CodeComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.SnippetSources.CodeComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.SnippetSources.CodeComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.SnippetSources.CodeComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.SnippetSources.CodeComponent} returns this
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jsheets.SnippetSources.CodeComponent} returns this
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.SnippetSources.CodeComponent} returns this
 */
proto.jsheets.SnippetSources.CodeComponent.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Snippet.Reference reference = 1;
 * @return {?proto.jsheets.Snippet.Reference}
 */
proto.jsheets.SnippetSources.prototype.getReference = function() {
  return /** @type{?proto.jsheets.Snippet.Reference} */ (
    jspb.Message.getWrapperField(this, jsheets_api_snippet_pb.Snippet.Reference, 1));
};


/**
 * @param {?proto.jsheets.Snippet.Reference|undefined} value
 * @return {!proto.jsheets.SnippetSources} returns this
*/
proto.jsheets.SnippetSources.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.SnippetSources} returns this
 */
proto.jsheets.SnippetSources.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.SnippetSources.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.jsheets.SnippetSources.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.SnippetSources} returns this
 */
proto.jsheets.SnippetSources.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CodeComponent code_components = 3;
 * @return {!Array<!proto.jsheets.SnippetSources.CodeComponent>}
 */
proto.jsheets.SnippetSources.prototype.getCodeComponentsList = function() {
  return /** @type{!Array<!proto.jsheets.SnippetSources.CodeComponent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.SnippetSources.CodeComponent, 3));
};


/**
 * @param {!Array<!proto.jsheets.SnippetSources.CodeComponent>} value
 * @return {!proto.jsheets.SnippetSources} returns this
*/
proto.jsheets.SnippetSources.prototype.setCodeComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jsheets.SnippetSources.CodeComponent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.SnippetSources.CodeComponent}
 */
proto.jsheets.SnippetSources.prototype.addCodeComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jsheets.SnippetSources.CodeComponent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.SnippetSources} returns this
 */
proto.jsheets.SnippetSources.prototype.clearCodeComponentsList = function() {
  return this.setCodeComponentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.Evaluation.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.Evaluation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.Evaluation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.Evaluation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.Evaluation}
 */
proto.jsheets.Evaluation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.Evaluation;
  return proto.jsheets.Evaluation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.Evaluation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.Evaluation}
 */
proto.jsheets.Evaluation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.Evaluation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.Evaluation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.Evaluation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.Evaluation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jsheets.Evaluation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.Evaluation} returns this
 */
proto.jsheets.Evaluation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jsheets.StartEvaluationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.StartEvaluationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.StartEvaluationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.StartEvaluationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.StartEvaluationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    snippet: (f = msg.getSnippet()) && proto.jsheets.EvaluatedSnippet.toObject(includeInstance, f),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.jsheets.SnippetSources.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.StartEvaluationRequest}
 */
proto.jsheets.StartEvaluationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.StartEvaluationRequest;
  return proto.jsheets.StartEvaluationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.StartEvaluationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.StartEvaluationRequest}
 */
proto.jsheets.StartEvaluationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jsheets.EvaluatedSnippet;
      reader.readMessage(value,proto.jsheets.EvaluatedSnippet.deserializeBinaryFromReader);
      msg.setSnippet(value);
      break;
    case 2:
      var value = new proto.jsheets.SnippetSources;
      reader.readMessage(value,proto.jsheets.SnippetSources.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.StartEvaluationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.StartEvaluationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.StartEvaluationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.StartEvaluationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnippet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jsheets.EvaluatedSnippet.serializeBinaryToWriter
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.jsheets.SnippetSources.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvaluatedSnippet snippet = 1;
 * @return {?proto.jsheets.EvaluatedSnippet}
 */
proto.jsheets.StartEvaluationRequest.prototype.getSnippet = function() {
  return /** @type{?proto.jsheets.EvaluatedSnippet} */ (
    jspb.Message.getWrapperField(this, proto.jsheets.EvaluatedSnippet, 1));
};


/**
 * @param {?proto.jsheets.EvaluatedSnippet|undefined} value
 * @return {!proto.jsheets.StartEvaluationRequest} returns this
*/
proto.jsheets.StartEvaluationRequest.prototype.setSnippet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.StartEvaluationRequest} returns this
 */
proto.jsheets.StartEvaluationRequest.prototype.clearSnippet = function() {
  return this.setSnippet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.StartEvaluationRequest.prototype.hasSnippet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SnippetSources sources = 2;
 * @return {!Array<!proto.jsheets.SnippetSources>}
 */
proto.jsheets.StartEvaluationRequest.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.jsheets.SnippetSources>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.SnippetSources, 2));
};


/**
 * @param {!Array<!proto.jsheets.SnippetSources>} value
 * @return {!proto.jsheets.StartEvaluationRequest} returns this
*/
proto.jsheets.StartEvaluationRequest.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.jsheets.SnippetSources=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.SnippetSources}
 */
proto.jsheets.StartEvaluationRequest.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jsheets.SnippetSources, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.StartEvaluationRequest} returns this
 */
proto.jsheets.StartEvaluationRequest.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jsheets.MissingSources.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.MissingSources.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.MissingSources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.MissingSources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.MissingSources.toObject = function(includeInstance, msg) {
  var f, obj = {
    missingSnippetsList: jspb.Message.toObjectList(msg.getMissingSnippetsList(),
    jsheets_api_snippet_pb.Snippet.Reference.toObject, includeInstance),
    cachingPolicy: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.MissingSources}
 */
proto.jsheets.MissingSources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.MissingSources;
  return proto.jsheets.MissingSources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.MissingSources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.MissingSources}
 */
proto.jsheets.MissingSources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new jsheets_api_snippet_pb.Snippet.Reference;
      reader.readMessage(value,jsheets_api_snippet_pb.Snippet.Reference.deserializeBinaryFromReader);
      msg.addMissingSnippets(value);
      break;
    case 2:
      var value = /** @type {!proto.jsheets.SourceCachingPolicy} */ (reader.readEnum());
      msg.setCachingPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.MissingSources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.MissingSources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.MissingSources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.MissingSources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissingSnippetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      jsheets_api_snippet_pb.Snippet.Reference.serializeBinaryToWriter
    );
  }
  f = message.getCachingPolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated Snippet.Reference missing_snippets = 1;
 * @return {!Array<!proto.jsheets.Snippet.Reference>}
 */
proto.jsheets.MissingSources.prototype.getMissingSnippetsList = function() {
  return /** @type{!Array<!proto.jsheets.Snippet.Reference>} */ (
    jspb.Message.getRepeatedWrapperField(this, jsheets_api_snippet_pb.Snippet.Reference, 1));
};


/**
 * @param {!Array<!proto.jsheets.Snippet.Reference>} value
 * @return {!proto.jsheets.MissingSources} returns this
*/
proto.jsheets.MissingSources.prototype.setMissingSnippetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jsheets.Snippet.Reference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.Snippet.Reference}
 */
proto.jsheets.MissingSources.prototype.addMissingSnippets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jsheets.Snippet.Reference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.MissingSources} returns this
 */
proto.jsheets.MissingSources.prototype.clearMissingSnippetsList = function() {
  return this.setMissingSnippetsList([]);
};


/**
 * optional SourceCachingPolicy caching_policy = 2;
 * @return {!proto.jsheets.SourceCachingPolicy}
 */
proto.jsheets.MissingSources.prototype.getCachingPolicy = function() {
  return /** @type {!proto.jsheets.SourceCachingPolicy} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jsheets.SourceCachingPolicy} value
 * @return {!proto.jsheets.MissingSources} returns this
 */
proto.jsheets.MissingSources.prototype.setCachingPolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.StopEvaluationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.StopEvaluationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.StopEvaluationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.StopEvaluationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.StopEvaluationRequest}
 */
proto.jsheets.StopEvaluationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.StopEvaluationRequest;
  return proto.jsheets.StopEvaluationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.StopEvaluationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.StopEvaluationRequest}
 */
proto.jsheets.StopEvaluationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.StopEvaluationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.StopEvaluationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.StopEvaluationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.StopEvaluationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jsheets.EvaluateRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.jsheets.EvaluateRequest.MessageCase = {
  MESSAGE_NOT_SET: 0,
  START: 1,
  STOP: 2
};

/**
 * @return {proto.jsheets.EvaluateRequest.MessageCase}
 */
proto.jsheets.EvaluateRequest.prototype.getMessageCase = function() {
  return /** @type {proto.jsheets.EvaluateRequest.MessageCase} */(jspb.Message.computeOneofCase(this, proto.jsheets.EvaluateRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.EvaluateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.EvaluateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.EvaluateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.jsheets.StartEvaluationRequest.toObject(includeInstance, f),
    stop: (f = msg.getStop()) && proto.jsheets.StopEvaluationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.EvaluateRequest}
 */
proto.jsheets.EvaluateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.EvaluateRequest;
  return proto.jsheets.EvaluateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.EvaluateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.EvaluateRequest}
 */
proto.jsheets.EvaluateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jsheets.StartEvaluationRequest;
      reader.readMessage(value,proto.jsheets.StartEvaluationRequest.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.jsheets.StopEvaluationRequest;
      reader.readMessage(value,proto.jsheets.StopEvaluationRequest.deserializeBinaryFromReader);
      msg.setStop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.EvaluateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.EvaluateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.EvaluateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jsheets.StartEvaluationRequest.serializeBinaryToWriter
    );
  }
  f = message.getStop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jsheets.StopEvaluationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional StartEvaluationRequest start = 1;
 * @return {?proto.jsheets.StartEvaluationRequest}
 */
proto.jsheets.EvaluateRequest.prototype.getStart = function() {
  return /** @type{?proto.jsheets.StartEvaluationRequest} */ (
    jspb.Message.getWrapperField(this, proto.jsheets.StartEvaluationRequest, 1));
};


/**
 * @param {?proto.jsheets.StartEvaluationRequest|undefined} value
 * @return {!proto.jsheets.EvaluateRequest} returns this
*/
proto.jsheets.EvaluateRequest.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.jsheets.EvaluateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.EvaluateRequest} returns this
 */
proto.jsheets.EvaluateRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.EvaluateRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StopEvaluationRequest stop = 2;
 * @return {?proto.jsheets.StopEvaluationRequest}
 */
proto.jsheets.EvaluateRequest.prototype.getStop = function() {
  return /** @type{?proto.jsheets.StopEvaluationRequest} */ (
    jspb.Message.getWrapperField(this, proto.jsheets.StopEvaluationRequest, 2));
};


/**
 * @param {?proto.jsheets.StopEvaluationRequest|undefined} value
 * @return {!proto.jsheets.EvaluateRequest} returns this
*/
proto.jsheets.EvaluateRequest.prototype.setStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.jsheets.EvaluateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.EvaluateRequest} returns this
 */
proto.jsheets.EvaluateRequest.prototype.clearStop = function() {
  return this.setStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.EvaluateRequest.prototype.hasStop = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.Text.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.Text.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.Text} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.Text.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.Text}
 */
proto.jsheets.Text.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.Text;
  return proto.jsheets.Text.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.Text} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.Text}
 */
proto.jsheets.Text.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.Text.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.Text.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.Text} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.Text.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.EvaluationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.EvaluationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.EvaluationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    output: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.EvaluationResult}
 */
proto.jsheets.EvaluationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.EvaluationResult;
  return proto.jsheets.EvaluationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.EvaluationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.EvaluationResult}
 */
proto.jsheets.EvaluationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentId(value);
      break;
    case 2:
      var value = /** @type {!proto.jsheets.EvaluationResult.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.EvaluationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.EvaluationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.EvaluationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.jsheets.EvaluationResult.Kind = {
  KIND_UNSPECIFIED: 0,
  INFO: 1,
  ERROR: 2
};

/**
 * optional string component_id = 1;
 * @return {string}
 */
proto.jsheets.EvaluationResult.prototype.getComponentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluationResult} returns this
 */
proto.jsheets.EvaluationResult.prototype.setComponentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Kind kind = 2;
 * @return {!proto.jsheets.EvaluationResult.Kind}
 */
proto.jsheets.EvaluationResult.prototype.getKind = function() {
  return /** @type {!proto.jsheets.EvaluationResult.Kind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jsheets.EvaluationResult.Kind} value
 * @return {!proto.jsheets.EvaluationResult} returns this
 */
proto.jsheets.EvaluationResult.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string output = 3;
 * @return {string}
 */
proto.jsheets.EvaluationResult.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluationResult} returns this
 */
proto.jsheets.EvaluationResult.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.CodeSpan.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.CodeSpan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.CodeSpan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.CodeSpan.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.CodeSpan}
 */
proto.jsheets.CodeSpan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.CodeSpan;
  return proto.jsheets.CodeSpan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.CodeSpan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.CodeSpan}
 */
proto.jsheets.CodeSpan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.CodeSpan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.CodeSpan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.CodeSpan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.CodeSpan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start = 1;
 * @return {number}
 */
proto.jsheets.CodeSpan.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jsheets.CodeSpan} returns this
 */
proto.jsheets.CodeSpan.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end = 2;
 * @return {number}
 */
proto.jsheets.CodeSpan.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jsheets.CodeSpan} returns this
 */
proto.jsheets.CodeSpan.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.EvaluationError.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.EvaluationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.EvaluationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    span: (f = msg.getSpan()) && proto.jsheets.CodeSpan.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.EvaluationError}
 */
proto.jsheets.EvaluationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.EvaluationError;
  return proto.jsheets.EvaluationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.EvaluationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.EvaluationError}
 */
proto.jsheets.EvaluationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.jsheets.CodeSpan;
      reader.readMessage(value,proto.jsheets.CodeSpan.deserializeBinaryFromReader);
      msg.setSpan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.EvaluationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.EvaluationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.EvaluationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpan();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jsheets.CodeSpan.serializeBinaryToWriter
    );
  }
};


/**
 * optional string component_id = 1;
 * @return {string}
 */
proto.jsheets.EvaluationError.prototype.getComponentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluationError} returns this
 */
proto.jsheets.EvaluationError.prototype.setComponentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kind = 2;
 * @return {string}
 */
proto.jsheets.EvaluationError.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluationError} returns this
 */
proto.jsheets.EvaluationError.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.jsheets.EvaluationError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jsheets.EvaluationError} returns this
 */
proto.jsheets.EvaluationError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CodeSpan span = 4;
 * @return {?proto.jsheets.CodeSpan}
 */
proto.jsheets.EvaluationError.prototype.getSpan = function() {
  return /** @type{?proto.jsheets.CodeSpan} */ (
    jspb.Message.getWrapperField(this, proto.jsheets.CodeSpan, 4));
};


/**
 * @param {?proto.jsheets.CodeSpan|undefined} value
 * @return {!proto.jsheets.EvaluationError} returns this
*/
proto.jsheets.EvaluationError.prototype.setSpan = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jsheets.EvaluationError} returns this
 */
proto.jsheets.EvaluationError.prototype.clearSpan = function() {
  return this.setSpan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jsheets.EvaluationError.prototype.hasSpan = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jsheets.EvaluateResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jsheets.EvaluateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jsheets.EvaluateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jsheets.EvaluateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    missingSourcesList: jspb.Message.toObjectList(msg.getMissingSourcesList(),
    proto.jsheets.MissingSources.toObject, includeInstance),
    textList: jspb.Message.toObjectList(msg.getTextList(),
    proto.jsheets.Text.toObject, includeInstance),
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.jsheets.EvaluationResult.toObject, includeInstance),
    errorList: jspb.Message.toObjectList(msg.getErrorList(),
    proto.jsheets.EvaluationError.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jsheets.EvaluateResponse}
 */
proto.jsheets.EvaluateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jsheets.EvaluateResponse;
  return proto.jsheets.EvaluateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jsheets.EvaluateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jsheets.EvaluateResponse}
 */
proto.jsheets.EvaluateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jsheets.MissingSources;
      reader.readMessage(value,proto.jsheets.MissingSources.deserializeBinaryFromReader);
      msg.addMissingSources(value);
      break;
    case 2:
      var value = new proto.jsheets.Text;
      reader.readMessage(value,proto.jsheets.Text.deserializeBinaryFromReader);
      msg.addText(value);
      break;
    case 3:
      var value = new proto.jsheets.EvaluationResult;
      reader.readMessage(value,proto.jsheets.EvaluationResult.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 4:
      var value = new proto.jsheets.EvaluationError;
      reader.readMessage(value,proto.jsheets.EvaluationError.deserializeBinaryFromReader);
      msg.addError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jsheets.EvaluateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jsheets.EvaluateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jsheets.EvaluateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jsheets.EvaluateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissingSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jsheets.MissingSources.serializeBinaryToWriter
    );
  }
  f = message.getTextList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.jsheets.Text.serializeBinaryToWriter
    );
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jsheets.EvaluationResult.serializeBinaryToWriter
    );
  }
  f = message.getErrorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.jsheets.EvaluationError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MissingSources missing_sources = 1;
 * @return {!Array<!proto.jsheets.MissingSources>}
 */
proto.jsheets.EvaluateResponse.prototype.getMissingSourcesList = function() {
  return /** @type{!Array<!proto.jsheets.MissingSources>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.MissingSources, 1));
};


/**
 * @param {!Array<!proto.jsheets.MissingSources>} value
 * @return {!proto.jsheets.EvaluateResponse} returns this
*/
proto.jsheets.EvaluateResponse.prototype.setMissingSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jsheets.MissingSources=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.MissingSources}
 */
proto.jsheets.EvaluateResponse.prototype.addMissingSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jsheets.MissingSources, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.EvaluateResponse} returns this
 */
proto.jsheets.EvaluateResponse.prototype.clearMissingSourcesList = function() {
  return this.setMissingSourcesList([]);
};


/**
 * repeated Text text = 2;
 * @return {!Array<!proto.jsheets.Text>}
 */
proto.jsheets.EvaluateResponse.prototype.getTextList = function() {
  return /** @type{!Array<!proto.jsheets.Text>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.Text, 2));
};


/**
 * @param {!Array<!proto.jsheets.Text>} value
 * @return {!proto.jsheets.EvaluateResponse} returns this
*/
proto.jsheets.EvaluateResponse.prototype.setTextList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.jsheets.Text=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.Text}
 */
proto.jsheets.EvaluateResponse.prototype.addText = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jsheets.Text, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.EvaluateResponse} returns this
 */
proto.jsheets.EvaluateResponse.prototype.clearTextList = function() {
  return this.setTextList([]);
};


/**
 * repeated EvaluationResult result = 3;
 * @return {!Array<!proto.jsheets.EvaluationResult>}
 */
proto.jsheets.EvaluateResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.jsheets.EvaluationResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.EvaluationResult, 3));
};


/**
 * @param {!Array<!proto.jsheets.EvaluationResult>} value
 * @return {!proto.jsheets.EvaluateResponse} returns this
*/
proto.jsheets.EvaluateResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jsheets.EvaluationResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.EvaluationResult}
 */
proto.jsheets.EvaluateResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jsheets.EvaluationResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.EvaluateResponse} returns this
 */
proto.jsheets.EvaluateResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * repeated EvaluationError error = 4;
 * @return {!Array<!proto.jsheets.EvaluationError>}
 */
proto.jsheets.EvaluateResponse.prototype.getErrorList = function() {
  return /** @type{!Array<!proto.jsheets.EvaluationError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jsheets.EvaluationError, 4));
};


/**
 * @param {!Array<!proto.jsheets.EvaluationError>} value
 * @return {!proto.jsheets.EvaluateResponse} returns this
*/
proto.jsheets.EvaluateResponse.prototype.setErrorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.jsheets.EvaluationError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jsheets.EvaluationError}
 */
proto.jsheets.EvaluateResponse.prototype.addError = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jsheets.EvaluationError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jsheets.EvaluateResponse} returns this
 */
proto.jsheets.EvaluateResponse.prototype.clearErrorList = function() {
  return this.setErrorList([]);
};


/**
 * @enum {number}
 */
proto.jsheets.SourceCachingPolicy = {
  POLICY_UNSPECIFIED: 0
};

goog.object.extend(exports, proto.jsheets);
